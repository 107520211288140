import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Statistiche")])]),_c(VCardSubtitle,{staticClass:"mb-8 mt-n5"},[_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v("Stai andando bene")]),_c('span',[_vm._v("😎 questo mese!")])]),_c(VCardText,[_c(VRow,_vm._l((_vm.statisticsData),function(data){return _c(VCol,{key:data.title,staticClass:"d-flex align-center",attrs:{"cols":"6","md":"3"}},[_c(VAvatar,{staticClass:"elevation-1",attrs:{"size":"44","color":_vm.resolveStatisticsIconVariation (data.title).color,"rounded":""}},[_c(VIcon,{attrs:{"dark":"","color":"white","size":"30"}},[_vm._v(" "+_vm._s(_vm.resolveStatisticsIconVariation (data.title).icon)+" ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-xs mb-0"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('h3',{staticClass:"text-xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolveStatisticsTotalVariation (data.title))+" ")])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }