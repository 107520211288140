<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Statistiche</span>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Stai andando bene</span>
      <span>😎 questo mese!</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ resolveStatisticsTotalVariation (data.title) }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiTrendingUp,
  mdiFileOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralStatistics',
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFileOutline,
      },
      statisticsData: [
        {
          title: 'Pratiche',
        },
        {
          title: 'Clienti',
        },
        {
          title: 'Product',
        },
        {
          title: 'Revenue',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['procedureList', 'customersList']),
  },
  methods: {
    resolveStatisticsIconVariation(data) {
      if (data === 'Pratiche') return { icon: mdiFileOutline, color: 'primary' }
      if (data === 'Clienti') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    },
    resolveStatisticsTotalVariation(data) {
      if (data === 'Pratiche') return this.procedureList.length
      if (data === 'Clienti') return this.customersList.length
      if (data === 'Product') return '1.54k'
      if (data === 'Revenue') return '$88k'

      return { icon: mdiAccountOutline, color: 'success' }
    },
  },
}
</script>
