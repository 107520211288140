<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <welcome-card
          btn-to="/notifications"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <general-statistics-card />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="8"
        xl="8"
      >
        <procedure-status-card />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="4"
        xl="4"
      >
        <v-row class="match-height">
          <v-col
            cols="12"
          >
            <document-status-card />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <procedures-completion-card
              :stat-title="salesRadialChart.statTitle"
              :statistics="salesRadialChart.statistics"
              :chart-series="salesRadialChart.series"
              :chart-color="$vuetify.theme.currentTheme.info"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <procedures-average-card />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <statistics-vertical-card
              :change="logisticsOptions.change"
              :color="logisticsOptions.color"
              :icon="logisticsOptions.icon"
              :statistics="logisticsOptions.statistics"
              :stat-title="logisticsOptions.statTitle"
              :subtitle="logisticsOptions.subtitle"
            ></statistics-vertical-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <statistics-vertical-card
              :change="reportsOptions.change"
              :color="reportsOptions.color"
              :icon="reportsOptions.icon"
              :statistics="reportsOptions.statistics"
              :stat-title="reportsOptions.statTitle"
              :subtitle="reportsOptions.subtitle"
            ></statistics-vertical-card>
          </v-col>

          <v-col
            cols="12"
            class="mt-3"
          >
            <new-customers-card />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <procedures-per-template-card />
      </v-col>
      <!--                <v-col
                    cols="12"
                    sm="12"
                    lg="6"
                    xl="3"
                    v-for="(card,idx) in cards"
                    :key="idx"
                >
                    <v-card
                        :color=card.bg_color
                        :dark="!!card.dark"
                        class="d-block"
                        :to="card.btn_to"
                    >
                        <v-card-title
                            class="text-h5"
                        >
                            {{ card.title }}
                            <v-spacer></v-spacer>
                            <span>{{ card.counter }}</span>
                        </v-card-title>

                        <v-card-subtitle>
                            {{ card.subtitle }}
                        </v-card-subtitle>

                        <v-card-actions>
                            <v-btn text>
                                {{ card.btn_text }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>-->
    </v-row>
    <v-row
      v-if="loggedUser.role === 'professional'"
      dense
    >
      <v-col
        cols="12"
        sm="12"
        lg="6"
        xl="3"
      >
        <v-card
          class="d-block"
        >
          <v-card-title
            class="text-h5"
          >
            Limite pratiche
            <v-spacer></v-spacer>
            {{ procedureCounters.open }} / {{ productLimits.proceduresNum }}
          </v-card-title>

          <v-card-subtitle v-if="!productFunctionalities.canCreateProcedure.details.number_limit">
            Non hai più possibilità di gestire nuove pratiche, archivia le pratiche già chiuse o cambia piano.
          </v-card-subtitle>

          <v-card-actions>
            <v-progress-linear
              :value="productPercentages.proceduresNum"
              height="20"
            >
              <strong>{{ Math.ceil(productPercentages.proceduresNum) }}%</strong>
            </v-progress-linear>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="6"
        xl="3"
      >
        <v-card
          class="d-block d-flex flex-column"
          height="100%"
        >
          <v-card-title
            class="text-h5"
          >
            Spazio pratiche
            <v-spacer></v-spacer>
            {{ formatWeight(procedureCounters.total_space) }} / {{ productLimits.proceduresSize }} GB
          </v-card-title>

          <v-card-subtitle v-if="!productFunctionalities.canCreateProcedure.details.space_limit">
            Non hai più spazio per gestire le tue pratiche, archivia le pratiche già chiuse o cambia piano.
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-progress-linear
              :value="productPercentages.proceduresSize"
              height="20"
            >
              <strong>{{ Math.ceil(productPercentages.proceduresSize) }}%</strong>
            </v-progress-linear>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>
            Ultime pratiche
          </v-card-title>
          <v-card-text>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Data
                      </th>
                      <th class="text-left">
                        Nome
                      </th>
                      <th class="text-left">
                        Descrizione
                      </th>
                      <th class="text-left">
                        Stato
                      </th>
                      <th class="text-left">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in last10Procedures"
                      :key="item.id"
                    >
                      <td>{{ item.createdAtFormatted }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.statusText }}</td>
                      <td>
                        <v-btn
                          class="ma-2"
                          color="primary"
                          dark
                          small
                          :to="item.linkTo"
                        >
                          <v-icon dark>
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiChartTimelineVariant, mdiCheck,
} from '@mdi/js'
import WelcomeCard from '@/components/cards/dashboard/welcome-card.vue'
import GeneralStatisticsCard from '@/components/cards/dashboard/general-statistics-card.vue'
import ProcedureStatusCard from '@/components/cards/dashboard/procedure-status-card.vue'
import DocumentStatusCard from '@/components/cards/dashboard/documents-status-card.vue'
import ProceduresCompletionCard from '@/components/cards/dashboard/procedures-completion-card.vue'
import ProceduresAverageCard from '@/components/cards/dashboard/procedures-average-card.vue'
import StatisticsVerticalCard from '@/components/cards/dashboard/statistics-vertical-card.vue'
import NewCustomersCard from '@/components/cards/dashboard/new-customers-card.vue'
import ProceduresPerTemplateCard from '@/components/cards/dashboard/procedures-per-template-card.vue'

export default {
  name: 'Dashboard',
  components: {
    ProceduresPerTemplateCard,
    NewCustomersCard,
    StatisticsVerticalCard,
    ProceduresAverageCard,
    ProceduresCompletionCard,
    DocumentStatusCard,
    GeneralStatisticsCard,
    WelcomeCard,
    ProcedureStatusCard,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
      ],
      cards: [
        {
          title: 'Pratiche non assegnate',
          subtitle: 'Ci sono pratiche che non sono ancora state assegnate',
          btn_text: "Vai all'elenco",
          btn_to: '#',
          bg_color: '',
          dark: false,
          counter: 0,
          ref: 'no_user',
        },
        {
          title: 'Pratiche aperte',
          subtitle: 'Elenco delle pratiche aperte',
          btn_text: 'Vai alle pratiche aperte',
          btn_to: '/procedures',
          bg_color: 'primary',
          dark: true,
          counter: 0,
          ref: 'open',
        },
        {
          title: 'Documenti da caricare',
          subtitle: 'Ci sono documenti da caricare',
          btn_text: 'Elenco dei documenti',
          btn_to: '#',
          bg_color: '',
          dark: false,
          counter: 0,
        },
        {
          title: 'Pratiche archiviate',
          subtitle: 'Elenco delle pratiche archiviate',
          btn_text: 'Elenco delle pratiche',
          btn_to: '/archived-procedures',
          bg_color: '',
          dark: false,
          counter: 0,
          ref: 'archived',
        },
      ],
      salesRadialChart: {
        statTitle: 'Completamento pratiche',
        statistics: '135k',
        series: [78],
      },
      logisticsOptions: {
        statTitle: 'Logistics',
        icon: mdiChartTimelineVariant,
        color: 'error',
        subtitle: 'Revenue Increase',
        statistics: '44.1k',
        change: '+12%',
      },
      reportsOptions: {
        statTitle: 'Reports',
        icon: mdiCheck,
        color: 'warning',
        subtitle: 'System Bugs',
        statistics: '268',
        change: '-8%',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList', 'procedureCounters', 'productPercentages', 'productLimits', 'productFunctionalities']),
    last10Procedures() {
      if (this.procedureList.length > 0) {
        return this.procedureList.slice(0, 10)
      }

      return false
    },
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
    this.setCounter()
  },
  methods: {
    setCounter() {
      this.cards.forEach((v, k) => {
        this.cards[k].counter = this.procedureCounters[v.ref]
      })
    },
    formatWeight(size) {
      let finalSize = size / 1000

      // mega
      if (finalSize > 1000000000) {
        finalSize /= 1000000000

        return `${finalSize.toFixed(2)} GB`
      }
      if (finalSize > 1000) {
        finalSize /= 1000

        return `${finalSize.toFixed(2)} MB`
      }

      return `${finalSize.toFixed(0)} KB`
    },
  },
}
</script>
