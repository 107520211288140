import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"greeting-card"},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{attrs:{"cols":"8"}},[_c(VCardTitle,{staticClass:"text-no-wrap pt-1 ps-2"},[_vm._v(" Bentornato "+_vm._s(_vm.loggedUser.name)+"! 🥳 ")]),_c(VCardSubtitle,{staticClass:"text-no-wrap ps-2"},[_vm._v(" Resta aggiornato sulle tue pratiche ")]),_c(VCardText,{staticClass:"d-flex align-center mt-2 pb-2 ps-2"},[_c('div',[(_vm.unreadNotifications.length > 0)?_c('p',{staticClass:"text-xl font-weight-semibold primary--text mb-2"},[_vm._v(" 3 nuove notifiche ")]):_c('p',{staticClass:"text-xl font-weight-semibold primary--text mb-2"},[_vm._v(" Nessuna nuova notifica ")]),(_vm.unreadNotifications.length > 0)?_c(VBtn,{attrs:{"small":"","color":"primary","to":_vm.btnTo}},[_vm._v(" Leggi tutte ")]):_vm._e()],1)])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VImg,{staticClass:"greeting-card-bg",attrs:{"contain":"","height":"180","width":"159","src":require(("@/assets/images/misc/triangle-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}}),_c(VAvatar,{staticClass:"greeting-card-trophy",attrs:{"size":"100","src":_vm.loggedUserAvatar}},[(_vm.loggedUserAvatar)?_c(VImg,{attrs:{"src":_vm.loggedUserAvatar}}):_c('span',[_vm._v(" "+_vm._s(_vm.loggedUser.initials)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }