<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          Bentornato {{ loggedUser.name }}! 🥳
        </v-card-title>
        <v-card-subtitle
          class="text-no-wrap ps-2"
        >
          Resta aggiornato sulle tue pratiche
        </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div>
            <p
              v-if="unreadNotifications.length > 0"
              class="text-xl font-weight-semibold primary--text mb-2"
            >
              3 nuove notifiche
            </p>
            <p
              v-else
              class="text-xl font-weight-semibold primary--text mb-2"
            >
              Nessuna nuova notifica
            </p>

            <v-btn
              v-if="unreadNotifications.length > 0"
              small
              color="primary"
              :to="btnTo"
            >
              Leggi tutte
            </v-btn>
          </div>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
          class="greeting-card-bg"
        />
        <v-avatar
          class="greeting-card-trophy"
          size="100"
          :src="loggedUserAvatar"
        >
          <v-img
            v-if="loggedUserAvatar"
            :src="loggedUserAvatar"
          />
          <span
            v-else
          >
            {{ loggedUser.initials }}
          </span>
        </v-avatar>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WelcomeCard',
  props: {
    btnTo: {
      type: String,
      default: '#',
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedUserAvatar', 'userNotifications']),
    unreadNotifications() {
      return this.userNotifications.filter(n => n.is_read === 'no')
    },
  },
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 5%;
  }
}
</style>
