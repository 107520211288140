import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Modelli più usati")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c(VList,{staticClass:"pb-0"},_vm._l((_vm.salesByCountries),function(data,index){return _c(VListItem,{key:data.country,class:("d-flex align-center px-0 " + (index > 0 ? 'mt-4':''))},[_c(VAvatar,{class:("v-avatar-light-bg " + (data.color) + "--text font-weight-medium me-3"),attrs:{"color":data.color,"size":"40"}},[_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(data.abbr))])]),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-2"},[_c('div',{staticClass:"font-weight-semibold"},[_c('span',{staticClass:"text--primary text-base me-1"},[_vm._v(_vm._s(data.amount))]),_c(VIcon,{attrs:{"size":"20","color":data.change.charAt(0) === '+' ? 'success':'error'}},[_vm._v(" "+_vm._s(data.change.charAt(0) === '+' ? _vm.icons.mdiChevronUp: _vm.icons.mdiChevronDown)+" ")]),_c('span',{class:("text-xs " + (data.change.charAt(0) === '+' ? 'success--text':'error--text'))},[_vm._v(_vm._s(data.change.slice(1)))])],1),_c(VListItemSubtitle,{staticClass:"text-xs"},[_vm._v(" "+_vm._s(data.country)+" ")])],1),_c(VSpacer),_c('div',[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(data.sales)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v("Sales")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }